import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import { Layout } from "../components/Layout";
export const pageTitle = "gizlilik politikası";
export const path = "/privacy";
export const _frontmatter = {};
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`gizlilik politikası`}</h1>
    <h2>{`bir bakışta`}</h2>
    <p>{`bu web sitesi;`}</p>
    <ul>
      <li parentName="ul">{`cookie, localStorage veya benzeri bir yöntemle tanımlama/izleme `}<strong parentName="li">{`yapmaz`}</strong>{`.`}</li>
      <li parentName="ul">{`üçüncü parti herhangi bir analitik aracı `}<strong parentName="li">{`kullanmaz`}</strong>{`.`}</li>
      <li parentName="ul">{`etkinliğinize ilişkin veri `}<strong parentName="li">{`saklamaz`}</strong>{`.`}</li>
      <li parentName="ul">{`hedeflenmiş reklam alanı `}<strong parentName="li">{`içermez`}</strong>{`.`}</li>
      <li parentName="ul">{`Google'a ait olan Firebase Hosting'de barınmaktadır. Etkinlikleriniz Firebase tarafından `}<strong parentName="li">{`izlenebilir`}</strong>{`.`}</li>
      <li parentName="ul">{`bu politikaya ilişkin sorularınızı `}<a parentName="li" {...{
          "href": "mailto:tolunyuksel@gmail.com"
        }}>{`tolunyuksel@gmail.com`}</a>{` adresine mail atarak iletebilirsiniz.`}</li>
    </ul>
    <h2>{`ayrıntılar`}</h2>
    <p>{`ytolun.com (veya yönlendirilen alanadı yukseltolun.com) yüksel tolun'un kişisel web sitesidir.`}</p>
    <h2>{`üçüncü parti servisler`}</h2>
    <h3>{`Firebase`}</h3>
    <p>{`bu sitenin barındırılması için Google, Inc. (“Google”)'e ait olan Firebase hizmetinin Hosting ürünü kullanılmaktadır. Siteyi ziyaret ettiğinizide etkinliğiniz Google ve/veya Firebase tarafından izlenebilir. Daha detaylı bilgi için Google'ın gizlilik politikasına göz atabilirsiniz: `}<a parentName="p" {...{
        "href": "https://policies.google.com/privacy"
      }}>{`https://policies.google.com/privacy`}</a></p>
    <h3>{`haklarınız`}</h3>
    <p>{`yukarıda açıklandığı üzere bu web sitesi üzerinde kişisel veri toplanmasına veya işlenmesine yönelik faaliyetler yürütülmez.
ancak gizliliğe ilişkin bir sorun yaşamanız halinde yasal düzenlemelerce size tanınan erişim,
itiraz veya unutulma haklarınızı kullanmak üzere `}<a parentName="p" {...{
        "href": "mailto:tolunyuksel@gmail.com"
      }}>{`tolunyuksel@gmail.com`}</a>{` adresine e-posta atabilirsiniz`}</p>
    <h3>{`gizlilik politikasında yapılacak değişiklikler`}</h3>
    <p>{`yüksel tolun dilediğinde bu politikada değişiklik yapma hakkını saklı tutar.`}</p>
    <p><strong parentName="p">{`son güncelleme:`}</strong>{` 29.11.2020`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      